:root {
  --color-primary: #cb1317;
  --color-secondary: #7f2325;
  --color-accent: #333;
  --color-headings: #413f3f;
  --color-body: #666;
}

@font-face {
  font-family: ubuntu;
  src: url("ubuntu-bold-webfont.47fd83ce.woff2") format("woff2"), url("ubuntu-bold-webfont.7eaa415c.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: ubuntu;
  src: url("ubuntu-bolditalic-webfont.d97f0965.woff2") format("woff2"), url("ubuntu-bolditalic-webfont.b27b5643.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: ubuntu;
  src: url("ubuntu-italic-webfont.26cfe533.woff2") format("woff2"), url("ubuntu-italic-webfont.0da1abe3.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: ubuntu;
  src: url("ubuntu-regular-webfont.a50285b9.woff2") format("woff2"), url("ubuntu-regular-webfont.17e3ac76.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  color: var(--color-body);
  font-family: ubuntu, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  font-size: 1.2rem;
}

article {
  margin-bottom: 2rem;
}

h1 {
  color: #eee;
  letter-spacing: .03em;
  text-shadow: 5px 5px 12px #000;
  text-transform: uppercase;
  font-size: 7vmin;
  font-style: normal;
  font-weight: bolder;
  line-height: 1;
}

h4 {
  font-size: 1rem;
}

article > h2 {
  background-color: var(--color-primary);
  color: #fff;
  border-radius: 15px;
  padding: 1rem;
  font-size: 1.5rem;
  box-shadow: 0 8px 16px #00000026;
}

a:target:before {
  content: "";
  height: 80px;
  display: block;
}

figure {
  border: thin solid silver;
  flex-flow: column;
  margin: auto;
  padding: 5px;
  display: flex;
}

figcaption {
  background-color: var(--color-primary);
  color: #fff;
  text-align: center;
  padding: .7rem;
}

footer {
  background-color: var(--color-accent);
}

h2.main_message_header {
  margin-bottom: 1rem;
  font-size: larger;
  font-weight: bolder;
}

div.main_message {
  text-align: center;
  flex-direction: column;
  display: flex;
}

@media screen and (min-width: 720px) {
  h1 {
    border-radius: 2vh;
    font-size: 5vmin;
  }

  article > h2 {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1440px) {
  h1 {
    border-radius: 2vh;
    font-size: 5vmin;
  }
}

p.main_p {
  margin-bottom: .3rem;
}

.bg-dark {
  background-color: var(--color-primary) !important;
}

textarea:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="week"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="color"]:focus, .uneditable-input:focus {
  border-color: var(--color-primary) !important;
  box-shadow: 0 1px 1px #00000013 inset, 0 0 8px var(--color-primary) !important;
  outline: 0 !important;
}

input[type="checkbox"]:checked {
  background-color: var(--color-primary);
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  box-shadow: inset 0 2px 5px #0000001a;
}

.modal-body {
  font-size: smaller;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  background-color: var(--color-primary);
  text-decoration: none;
}

.icon {
  width: 40px;
  height: 40px;
}

.icon--small {
  width: 30px;
  height: 30px;
}

.icon--large {
  width: 125px;
  height: 75px;
}

.icon--large-wide {
  width: 180px;
  height: 75px;
}

.icon--primary {
  fill: var(--color-primary);
}

.icon--white {
  fill: #fff;
}

.icon-container {
  width: 64px;
  height: 64px;
  background: #f3f9fa;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.icon-container--accent {
  background: var(--color-accent);
}

.btn {
  cursor: pointer;
  max-height: 50px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  border: 0;
  border-radius: 40rem;
  margin: 0 0;
  padding: .5rem 4vw;
  font-size: 1.3rem;
  font-weight: 600;
}

.btn .icon {
  width: 2rem;
  height: 2rem;
  vertical-align: middle;
  margin-right: 1rem;
}

.btn--primary {
  background: var(--color-primary);
  color: #fff;
}

.btn--primary:hover {
  background: var(--color-secondary);
  color: #fff;
}

.btn--secondary {
  color: var(--color-primary);
  background: #fff;
}

.btn--secondary:hover {
  color: var(--color-accent);
  background: #f2f2f2;
  box-shadow: 0 8px 16px #00000026;
}

.btn--success {
  color: #fff;
  background: green;
}

.btn--success:hover {
  color: green;
  background: #f2f2f2;
  box-shadow: 0 8px 16px #00000026;
}

.btn--danger {
  color: #fff;
  background: red;
}

.btn--danger:hover {
  color: red;
  background: #f2f2f2;
  box-shadow: 0 8px 16px #00000026;
}

.btn--accent {
  background: var(--color-accent);
  color: #fff;
}

.btn--accent:hover {
  background: var(--color-primary);
}

.btn--outline {
  color: var(--color-headings);
  border: 2px solid var(--color-headings);
  background: #fff;
}

.btn--outline:hover {
  background: var(--color-headings);
  color: #fff;
}

.btn--stretched {
  padding-left: 6rem;
  padding-right: 6rem;
}

.btn--small {
  margin: 0 !important;
  padding: 4px 15px !important;
}

.btn--block {
  width: 100%;
  display: inline-block;
}

@media screen and (min-width: 960px) {
  .btn {
    padding: .5rem 2vw;
    font-size: 1rem;
  }
}

@media screen and (min-width: 1200px) {
  .background {
    background-position: 0, 100%;
    background-repeat: no-repeat, no-repeat;
    background-size: contain, contain;
    background-attachment: fixed, fixed;
  }

  .background--kungfu {
    background-image: url("dennis-seet-bare-hands-pose-001.dc053123.webp"), url("julia-schander-knives-pose-1.17299025.webp");
  }

  .background--liondance {
    background-image: url("da-tou-fo.ee34c803.webp"), url("liondance-right.4444c2d5.webp");
  }
}

.spacer {
  height: 6rem;
  background-color: #0000;
}

a {
  text-decoration: none;
}

.link-arrow, .link {
  color: var(--color-primary);
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
}

.link-arrow:hover, .link:hover {
  color: var(--color-secondary);
  transition: color .5s;
}

.link-arrow:after {
  content: "-->";
  margin-left: 5px;
  transition: margin .15s;
}

.link-arrow:hover:after {
  color: var(--color-secondary);
  margin-left: 10px;
}

.link--light {
  color: #fff;
}

.link--light:hover {
  background-color: #fff;
  border-radius: 10px;
  padding: 5px 10px;
}

.image {
  border: 1px solid #d3d3d3;
  margin: 0 0 2rem;
  padding: .5rem;
}

.image > picture > img {
  width: 100%;
}

.image > figcaption {
  font-size: .8rem;
}

.image__masters {
  max-width: 120px;
}

@media screen and (min-width: 960px) {
  .image {
    max-width: 25%;
  }

  .image > figcaption {
    font-size: 1rem;
  }

  .image__left {
    float: left;
    margin-bottom: .6rem;
    margin-right: 1rem;
  }

  .image__right {
    float: right;
    margin-bottom: .6rem;
    margin-left: 2rem;
  }

  .image--w-30 {
    max-width: 30%;
  }

  .image--w-35 {
    max-width: 35%;
  }
}

.quote {
  border-left: .5rem solid var(--color-primary);
  color: var(--color-primary);
  font-size: 1.5rem;
  font-style: italic;
  line-height: 1.3;
}

.quote__text {
  margin-left: 1rem;
}

.quote__text:before {
  content: open-quote;
}

.quote__text:after {
  content: close-quote;
}

@media screen and (min-width: 960px) {
  .quote__text {
    margin-left: 1rem;
  }
}

.hero {
  justify-content: top;
  text-align: center;
  width: 100%;
  height: 35vh;
  clip-path: polygon(100% 0, 100% 95%, 50% 100%, 0 95%, 0 0);
  flex-direction: column;
  align-items: center;
  margin-top: 55px;
  display: flex;
  overflow: hidden;
  background-size: cover !important;
}

.hero__main {
  background-color: var(--color-primary);
  text-align: center;
  clip-path: polygon(100% 0, 100% 95%, 50% 100%, 0 95%, 0 0);
  padding-top: 80px;
  padding-bottom: 80px;
}

.hero__main--heading-one {
  margin: 0 20px 30px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 30px;
  line-height: 1.2;
}

.hero__main--heading-one > span {
  font-size: larger;
}

.hero__main--heading-two {
  color: #fff;
  padding: 0 20px;
  font-size: 18px;
}

.hero__kung-fu {
  background: url("kung-fu.d16a7f3a.webp") center no-repeat;
}

.hero__loewentanz {
  background: url("loewentanz.3243131d.webp") center no-repeat;
}

.hero__drachentanz {
  background: url("dragondance.15ac51ae.webp") center no-repeat;
}

.hero__taijiquan {
  background: url("taijiquan.9078a72c.webp") center no-repeat;
}

.hero__about-us {
  background: url("ueber-uns.e36548da.webp") center no-repeat;
}

.hero__kung-fu--heading-one {
  clip-path: polygon(100% 0, 100% 90%, 50% 100%, 0 90%, 0 0);
  background-color: #0006;
  margin-top: 2vh;
  padding: 15px 30px 30px;
}

.hero__kung-fu--heading-one > span {
  font-size: larger;
}

.hero--background-primary {
  background-color: var(--color-primary);
  clip-path: polygon(100% 0, 100% 90%, 50% 100%, 0 90%, 0 0);
  border-radius: 0;
  margin-top: 55px;
  padding: 15px 30px 30px;
}

.hero--background-primary > span {
  font-size: larger;
}

@media screen and (min-width: 720px) {
  .hero {
    height: 75vh;
    justify-content: top;
    align-items: flex-start;
    padding-top: 4vh;
    padding-left: 8vh;
  }

  .hero__main--image {
    width: 100%;
    height: 100%;
    max-width: 280px;
    max-height: 280px;
    border-radius: 140px;
    box-shadow: 5px 5px 12px #000000b3;
  }

  .hero__main--heading-one {
    text-align: center;
    font-size: 50px;
  }

  .hero__main--heading-two {
    font-size: 20px;
  }

  .hero__kung-fu--heading-one {
    border-radius: 0;
    margin-top: 0;
  }
}

.trial-training {
  background-color: var(--color-primary);
  clip-path: polygon(100% 0, 100% 95%, 50% 100%, 0 95%, 0 0);
}

.trial-training__heading {
  color: #fff;
  text-shadow: 5px 5px 12px #000;
  font-size: 2.5rem;
}

.trial-training__container {
  text-align: center;
  margin-left: 0;
}

.trial-training__header3 {
  color: #fff;
}

@media screen and (min-width: 960px) {
  .trial-training__heading {
    text-align: start;
    font-size: 3rem;
  }

  .trial-training__container {
    text-align: left;
    margin-left: 2rem;
  }

  .trial-training__list {
    list-style-type: disc;
  }

  .trial-training__list > li {
    font-size: 2rem;
  }
}

.news {
  visibility: visible;
  text-align: center;
  display: block;
}

.news > h2 {
  color: #fff;
  background-color: #cb1317;
  border-radius: 20px;
  padding: 1rem;
  font-size: 2.2rem;
  display: inline;
}

.news > article > h3 {
  margin-top: 1rem;
}

.news > article {
  background-color: #f2f2f2;
  padding: 1rem;
}

.time-table__row {
  background-color: #e6e6e6;
}

.time-table__cell {
  background-image: linear-gradient(var(--color-primary), #e6e6e6);
  color: #fff;
}

@media screen and (min-width: 960px) {
  .time-table__cell {
    background-image: linear-gradient(to right, var(--color-primary), #e6e6e6);
    color: #fff;
  }
}

.plan {
  transition: transform .2s ease-out;
}

.plan__price {
  text-shadow: 2px 2px 4px #ccc;
  font-size: 2rem;
}

.plan__billing-cycle {
  opacity: .8;
  margin-right: 1rem;
  font-size: 1.4rem;
  font-weight: 300;
}

.plan__description {
  letter-spacing: 1px;
  font-size: 1rem;
  font-weight: 300;
  display: block;
}

.feature {
  max-width: 10px;
}

.address--header {
  margin: 5px 0;
  font-size: 1.5rem;
  font-weight: bold;
}

.footer__heading {
  color: #d3d3d3;
  font-size: 1.5rem;
}

.footer__link {
  font-size: 1.1rem;
}

.nav-link {
  color: #bfbfbf;
}

.nav-link:hover {
  color: var(--color-primary);
}

.list-inline-item {
  color: #bfbfbf;
}

.img__circle {
  clip-path: circle(50%);
  background-image: linear-gradient(#d3d3d3, #fff);
}

.modal-body > img {
  width: 100%;
  height: 100%;
}

.cookies {
  background-color: var(--color-primary);
  color: #fff;
  height: auto;
  padding: 5px 10px 30px;
}

.privacy-policy {
  overflow-wrap: break-word;
}

.privacy-policy__emph-paragraph {
  margin-top: 15px;
}

.privacy-policy__emph-paragraph > a {
  text-decoration: none;
}

.contact__map {
  width: 100%;
  height: 30rem;
  border: 0;
}

.contact__textarea {
  height: 200px !important;
  resize: none !important;
}

/*# sourceMappingURL=index.67ef96c2.css.map */
