:root {
  --color-primary: #cb1317;
  --color-secondary: #7f2325;
  --color-accent: #333333;
  --color-headings: rgb(65, 63, 63);
  /* --color-body: #868e96; */
  --color-body: #666;
}

@font-face {
  font-family: "ubuntu";
  src: url("fonts/ubuntu/ubuntu-bold-webfont.woff2") format("woff2"), url("fonts/ubuntu/ubuntu-bold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ubuntu";
  src: url("fonts/ubuntu/ubuntu-bolditalic-webfont.woff2") format("woff2"), url("fonts/ubuntu/ubuntu-bolditalic-webfont.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "ubuntu";
  src: url("fonts/ubuntu/ubuntu-italic-webfont.woff2") format("woff2"), url("fonts/ubuntu/ubuntu-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "ubuntu";
  src: url("fonts/ubuntu/ubuntu-regular-webfont.woff2") format("woff2"), url("fonts/ubuntu/ubuntu-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  color: var(--color-body);
  font-family: "ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.2rem;
}

article {
  margin-bottom: 2rem;
}

h1 {
  color: #eee;
  font-size: 7vmin;
  font-style: normal;
  font-weight: bolder;
  letter-spacing: 0.03em;
  line-height: 1;
  text-shadow: 5px 5px 12px rgba(0, 0, 0, 1);
  text-transform: uppercase;
}

h4 {
  font-size: 1rem;
}

article > h2 {
  background-color: var(--color-primary);
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.15) 0 8px 16px 0;
  color: white;
  font-size: 1.5rem;
  padding: 1rem;
}

a:target::before {
  content: "";
  display: block;
  height: 80px;
}

figure {
  border: thin #c0c0c0 solid;
  display: flex;
  flex-flow: column;
  margin: auto;
  padding: 5px;
}

figcaption {
  background-color: var(--color-primary);
  color: #fff;
  padding: 0.7rem;
  text-align: center;
}

footer {
  background-color: var(--color-accent);
}

h2.main_message_header {
  font-size: larger;
  font-weight: bolder;
  margin-bottom: 1rem;
} 

div.main_message {
  display: flex;
  flex-direction: column;
  text-align: center;
}

@media screen and (min-width: 720px) {
  h1 {
    border-radius: 2vh;
    font-size: 5vmin;
  }

  article > h2 {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1440px) {
  h1 {
    border-radius: 2vh;
    font-size: 5vmin;
  }
}

p.main_p {
  margin-bottom: 0.3rem;
}

/* Bootstrap Overrides */
.bg-dark {
  background-color: var(--color-primary) !important;
}
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: var(--color-primary) !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px var(--color-primary) !important;
  outline: 0 none !important;
}

input[type="checkbox"]:checked {
  background-color: var(--color-primary);
  border: 1px solid rgb(242, 242, 242);
  border-radius: 4px;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
}

.modal-body {
  font-size: smaller;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: var(--color-primary);
}

/* Icons */
.icon {
  width: 40px;
  height: 40px;
}

.icon--small {
  width: 30px;
  height: 30px;
}

.icon--large {
  width: 125px;
  height: 75px;
}

.icon--large-wide {
  width: 180px;
  height: 75px;
}

.icon--primary {
  fill: var(--color-primary);
}

.icon--white {
  fill: white;
}

.icon-container {
  background: #f3f9fa;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.icon-container--accent {
  background: var(--color-accent);
}

/* Buttons */
.btn {
  border-radius: 40rem;
  border: 0;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 600;
  margin: 0rem 0;
  max-height: 50px;
  padding: 0.5rem 4vw;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
}

.btn .icon {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  vertical-align: middle;
}

.btn--primary {
  background: var(--color-primary);
  color: white;
}

.btn--primary:hover {
  background: var(--color-secondary);
  color: white;
}

.btn--secondary {
  background: white;
  color: var(--color-primary);
}

.btn--secondary:hover {
  background: #f2f2f2;
  color: var(--color-accent);
  box-shadow: rgba(0, 0, 0, 0.15) 0 8px 16px 0;
}

.btn--success {
  background: green;
  color: white;
}

.btn--success:hover {
  background: #f2f2f2;
  color: green;
  box-shadow: rgba(0, 0, 0, 0.15) 0 8px 16px 0;
}

.btn--danger {
  background: red;
  color: white;
}

.btn--danger:hover {
  background: #f2f2f2;
  color: red;
  box-shadow: rgba(0, 0, 0, 0.15) 0 8px 16px 0;
}

.btn--accent {
  background: var(--color-accent);
  color: white;
}

.btn--accent:hover {
  background: var(--color-primary);
}

.btn--outline {
  background: white;
  color: var(--color-headings);
  border: 2px solid var(--color-headings);
}

.btn--outline:hover {
  background: var(--color-headings);
  color: white;
}

.btn--stretched {
  padding-left: 6rem;
  padding-right: 6rem;
}

.btn--small {
  margin: 0 !important;
  padding: 4px 15px !important;
}

.btn--block {
  width: 100%;
  display: inline-block;
}

@media screen and (min-width: 960px) {
  .btn {
    font-size: 1rem;
    padding: 0.5rem 2vw;
  }
}

/* Backgrounds */
@media screen and (min-width: 1200px) {
  .background {
    background-position: left center, right center;
    background-repeat: no-repeat, no-repeat;
    background-attachment: fixed, fixed;
    background-size: contain, contain;
  }

  .background--kungfu {
    background-image: url(../images/backgrounds/dennis-seet-bare-hands-pose-001.webp), url(../images/backgrounds/julia-schander-knives-pose-1.webp);
  }

  .background--liondance {
    background-image: url(../images/backgrounds/da-tou-fo.webp), url(../images/backgrounds/liondance-right.webp);
  }
}

.spacer {
  height: 6rem;
  background-color: transparent;
}

/* Links */
a {
  text-decoration: none;
}

.link-arrow,
.link {
  color: var(--color-primary);
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
}

.link-arrow:hover,
.link:hover {
  color: var(--color-secondary);
  transition: color 0.5s;
}

.link-arrow::after {
  content: "-->";
  margin-left: 5px;
  transition: margin 0.15s;
}

.link-arrow:hover::after {
  margin-left: 10px;
  color: var(--color-secondary);
}

.link--light {
  color: white;
}

.link--light:hover {
  background-color: white;
  padding: 5px 10px;
  border-radius: 10px;
}

/* Images */
.image {
  padding: 0.5rem;
  margin: 0 0 2rem 0; /* 1rem */
  border: 1px solid lightgray;
}

.image > picture > img {
  width: 100%;
}

.image > figcaption {
  font-size: 0.8rem;
}

.image__masters {
  max-width: 120px;
}

@media screen and (min-width: 960px) {
  .image {
    max-width: 25%;
  }

  .image > figcaption {
    font-size: 1rem;
  }

  .image__left {
    float: left;
    margin-right: 1rem;
    margin-bottom: 0.6rem;
  }

  .image__right {
    float: right;
    margin-left: 2rem;
    margin-bottom: 0.6rem;
  }

  .image--w-30 {
    max-width: 30%;
  }

  .image--w-35 {
    max-width: 35%;
  }
}

/* Quotes */
.quote {
  border-left: 0.5rem solid var(--color-primary);
  font-size: 1.5rem;
  font-style: italic;
  color: var(--color-primary);
  line-height: 1.3;
}

.quote__text {
  margin-left: 1rem;
}

.quote__text::before {
  content: open-quote;
}

.quote__text::after {
  content: close-quote;
}

@media screen and (min-width: 960px) {
  .quote__text {
    margin-left: 1rem;
  }
}

/* Hero */
.hero {
  display: flex;
  justify-content: top;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 35vh; /* if you don't want it to take up the full screen, reduce this number */
  overflow: hidden;
  background-size: cover !important;
  margin-top: 55px;
  clip-path: polygon(100% 0, 100% 95%, 50% 100%, 0 95%, 0 0);
}

.hero__main {
  background-color: var(--color-primary);
  text-align: center;
  clip-path: polygon(100% 0, 100% 95%, 50% 100%, 0 95%, 0 0);
  padding-top: 80px;
  padding-bottom: 80px;
}

.hero__main--heading-one {
  font-size: 30px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.2;
  margin: 0 20px 30px 20px;
}

.hero__main--heading-one > span {
  font-size: larger;
}

.hero__main--heading-two {
  color: white;
  font-size: 18px;
  padding: 0 20px;
}

.hero__kung-fu {
  background: url(../images/hero/kung-fu.webp) no-repeat center center scroll;
}

.hero__loewentanz {
  background: url(../images/hero/loewentanz.webp) no-repeat center center scroll;
}

.hero__drachentanz {
  background: url(../images/hero/dragondance.webp) no-repeat center center scroll;
}

.hero__taijiquan {
  background: url(../images/hero/taijiquan.webp) no-repeat center center scroll;
}

.hero__about-us {
  background: url(../images/hero/ueber-uns.webp) no-repeat center center scroll;
}

.hero__kung-fu--heading-one {
  background-color: rgba(0, 0, 0, 0.4);
  /* border-radius: 1.5vh; */
  margin-top: 2vh;
  padding: 15px 30px 30px 30px;
  clip-path: polygon(100% 0, 100% 90%, 50% 100%, 0 90%, 0 0);
}

.hero__kung-fu--heading-one > span {
  font-size: larger;
}

.hero--background-primary {
  background-color: var(--color-primary);
  border-radius: 0;
  margin-top: 55px;
  padding: 15px 30px 30px 30px;
  clip-path: polygon(100% 0, 100% 90%, 50% 100%, 0 90%, 0 0);
}

.hero--background-primary > span {
  font-size: larger;
}

@media screen and (min-width: 720px) {
  .hero {
    height: 75vh;
    justify-content: top;
    align-items: flex-start;
    padding-left: 8vh;
    padding-top: 4vh;
  }

  .hero__main--image {
    box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.7);
    border-radius: 140px;
    width: 100%;
    height: 100%;
    max-width: 280px;
    max-height: 280px;
  }

  .hero__main--heading-one {
    font-size: 50px;
    text-align: center;
    /* margin: 0 10px 50px 10px; */
  }

  .hero__main--heading-two {
    font-size: 20px;
  }

  .hero__kung-fu--heading-one {
    margin-top: 0;
    border-radius: 0;
    /* padding-bottom: 20px; */
  }
}

/* Trial Training */
.trial-training {
  background-color: var(--color-primary);
  clip-path: polygon(100% 0, 100% 95%, 50% 100%, 0 95%, 0 0);
}

.trial-training__heading {
  color: white;
  font-size: 2.5rem;
  text-shadow: 5px 5px 12px rgba(0, 0, 0, 1);
}

.trial-training__container {
  margin-left: 0;
  text-align: center;
}

.trial-training__header3 {
  color: white;
}

@media screen and (min-width: 960px) {
  .trial-training__heading {
    font-size: 3rem;
    text-align: start;
  }

  .trial-training__container {
    margin-left: 2rem;
    text-align: left;
  }

  .trial-training__list {
    list-style-type: disc;
  }

  .trial-training__list > li {
    font-size: 2rem;
  }
}

/* News */


.news {
  display: block;
  visibility: visible;
  text-align: center;
}

.news > h2 {
  background-color: #cb1317;
  border-radius: 20px;
  color: white;
  display: inline;
  font-size: 2.2rem;
  padding: 1rem;
}

.news > article > h3 {
  margin-top: 1rem;
}

.news > article {
  background-color: #f2f2f2;
  padding: 1rem;
}

/* Time-Table */
.time-table__row {
  background-color: #e6e6e6;
}

.time-table__cell {
  background-image: linear-gradient(var(--color-primary), #e6e6e6);
  color: white;
}

@media screen and (min-width: 960px) {
  .time-table__cell {
    background-image: linear-gradient(to right, var(--color-primary), #e6e6e6);
    color: white;
  }
}

/* Plans */
.plan {
  transition: transform 0.2s ease-out;
}

.plan__price {
  font-size: 2rem;
  text-shadow: 2px 2px 4px #cccccc;
}

.plan__billing-cycle {
  font-size: 1.4rem;
  font-weight: 300;
  opacity: 0.8;
  margin-right: 1rem;
}

.plan__description {
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 1px;
  display: block;
}

/* Feature */
.feature {
  max-width: 10px;
}

/* Address */
.address--header {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 5px 0 5px 0;
}

/* Footer */
.footer__heading {
  color: lightgray;
  font-size: 1.5rem;
}

.footer__link {
  font-size: 1.1rem;
}

.nav-link {
  color: #bfbfbf;
}

.nav-link:hover {
  color: var(--color-primary);
}

.list-inline-item {
  color: #bfbfbf;
}

/* Helpers */
.img__circle {
  clip-path: circle(50% at 50% 50%);
  background-image: linear-gradient(lightgrey, white);
}

.modal-body > img {
  width: 100%;
  height: 100%;
}

/* Cookies */
.cookies {
  background-color: var(--color-primary);
  color: white;
  height: auto;
  padding: 5px 10px 30px 10px;
}

/* Privcy Policy */
.privacy-policy {
  overflow-wrap: break-word;
}

.privacy-policy__emph-paragraph {
  margin-top: 15px;
}

.privacy-policy__emph-paragraph > a {
  text-decoration: none;
}

/* Contact */
.contact__map {
  border: 0;
  width: 100%;
  height: 30rem;
}

.contact__textarea {
  height: 200px !important;
  resize: none !important;
}
